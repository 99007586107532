import React from "react";

// import Carrusel from "../components/Carrusel";
// import ArtistasBrochure from "../components/ArtistasBrochure";
// import LanzamientosList from "../components/Lanzamientos";
// import NoticiasList from "../components/Noticias";
// import EventosList from "../components/Eventos";
// import { Grid } from "@material-ui/core";

function Inicio() {
  return (
    <div>
      {/* <h1>Inicio</h1> 


      <div>
        <ArtistasBrochure />
      </div>

      <br />

      <div>
        Lanzamientos
        <LanzamientosList />
      </div>

      <br />

      <div>
        Noticias
        <NoticiasList />
      </div>

      <br />

      <div>
        Eventos
        <EventosList />
      </div>
      */}


    </div>
  );
}

export default Inicio;
