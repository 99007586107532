import React from "react";
//import axios from 'axios';
import "./App.css";
import Nav from "./components/Nav";

import Inicio from "./routes/Inicio";
import Noticias from "./routes/Noticias";
import Agenda from "./routes/Agenda";
import Artistas from "./routes/Artistas";
import Musica from "./routes/Musica";
import Videos from "./routes/Videos";
import Store from "./routes/Store";
import Corporativo from "./routes/Corporativo";
import Informes from "./routes/Informes";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "fontsource-roboto";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Grid } from "@material-ui/core";

const App = () => {
  return (
    <Router>
      <div className="App">
        <CssBaseline />

        <Grid container>
          <Grid item xs={12}>
            <Nav />
          </Grid>

          <Grid item xs={12}>
            <Switch>
              <Route path="/" exact component={Inicio} />
              <Route path="/noticias" component={Noticias} />
              <Route path="/agenda" component={Agenda} />
              <Route path="/artistas" exact component={Artistas} />
              <Route path="/artistas/:artist" exact component={Artistas} />
              <Route path="/musica" component={Musica} />
              <Route path="/videos" component={Videos} />
              <Route path="/store" component={Store} />
              <Route path="/corporativo" component={Corporativo} />
              <Route path="/informes" component={Informes} />
            </Switch>
          </Grid>
          {/*
          <Grid item xs={12}>
            footer
          </Grid>
          */}
        </Grid>
      </div>
    </Router>
  );
}

export default App;



