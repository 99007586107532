import { useState, useEffect } from 'react'
import axios from 'axios'
import ArtistaInfo from '../components/ArtistaInfo'
import ArtistaAlbums from '../components/ArtistaAlbums'
import ArtistaSingles from '../components/ArtistaSingles'
import ArtistasBrochure from "../components/ArtistasBrochure"
import YTChannelVideos from "../components/YTChannelVideos"
import { Grid } from '@material-ui/core'
import { Container } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import host from '../host';


/***
 * 
 * Si la consulta no contiene un id de artista, entonces se
 * proseguirá a cargar el broucher de artistas
 * 
 ***/


const LoadBrochure = () => {

  const [artistDB, setArtistDB] = useState([]);

  useEffect(() => {

    const params = new URLSearchParams();

    const fetchData = async () => {
        try {
            await axios
            .post(host + 'api/artistas/list', params, {use: 'no-cors'})
            .then(apiCall => {
              setArtistDB(apiCall.data.result);
              //console.log(apiCall);
              //console.log(apiRes);
            })

        } catch (e) {
            console.log(e);
        }
    };

    fetchData();
  }, []);

  return (
    <Container maxWidth="lg">
      <ArtistasBrochure artistDB={artistDB} />
    </Container>
  )
}

/***
 * 
 * Si la consulta contiene un id para un artista, entonces
 * proseguirá a cargar la información para dicho artista
 * 
 ***/

const LoadArtist = (artist) => {

  // console.log(artist.artist);
  const [artistDB, setArtistDB] = useState([]);
  const [albumDB, setAlbumDB] = useState([]);
  const [singleDB, setSingleDB] = useState([]);

  useEffect(() => 
  {
    const params = new URLSearchParams();
    params.append('modo', artist.artist);

    const fetchArtistDB = async () => 
    {
      try {
          await axios
          .post(host + 'api/artistas/view', params, {use: 'no-cors'})
          .then(apiCall => {
            setArtistDB(apiCall.data);
            //console.log(apiCall);
            //console.log(apiRes);
          })

      } catch (e) {
          console.log(e);
      }
    };

    const fetchAlbumDB = async () => 
    {
      try {
          await axios
          .post(host + 'api/albums/list', params, {use: 'no-cors'})
          .then(apiCall => {
            setAlbumDB(apiCall.data);
            //console.log(apiCall);
            //console.log(apiRes);
          })

      } catch (e) {
          console.log(e);
      }
    };

    const fetchSingleDB = async () => 
    {
      try {
          await axios
          .post(host + 'api/singles/list', params, {use: 'no-cors'})
          .then(apiCall => {
            setSingleDB(apiCall.data);
            //console.log(apiCall);
            //console.log(apiRes);
          })

      } catch (e) {
          console.log(e);
      }
    };

    fetchArtistDB();
    fetchAlbumDB();
    fetchSingleDB();
  }, []);

  return (
    <Container maxWidth="lg" style={{marginTop: '20px'}}>
      <Grid container direction="column" wrap="nowrap" spacing={1}>

        <Grid item>
          <ArtistaInfo data={artistDB.result} />
        </Grid>
        <Grid item>
          <YTChannelVideos data={artistDB.result} />
        </Grid>
        <Grid item style={{marginTop: '30px'}}>
          <ArtistaAlbums data={albumDB.result} />
        </Grid>
        <Grid item>
          <ArtistaSingles data={singleDB.result} />
        </Grid>

      </Grid>
    </Container>
  );
}

/***
 * 
 * Componente principal Artistas
 * 
 ***/

function Artistas() {

  const { artist } = useParams();
  const showBrochure = typeof artist !== 'undefined' ? false : true;
  // console.log(artist);
  // console.log(showBrochure);


  if(showBrochure){
    return (
      <>
        <LoadBrochure />
      </>      
    )
  }

  return (
    <>
      <LoadArtist artist={artist} />
    </>      
  )
}

export default Artistas;
