import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { withRouter, Link } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import menuBack from '../images/menu-back.png';
import LPMLogo from '../images/lpm_logo_exact.png';

import facebookIcon from '../images/social-icons/facebook.svg';
import instagramIcon from '../images/social-icons/instagram.svg';
import twitterIcon from '../images/social-icons/twitter.svg';
import youtubeIcon from '../images/social-icons/youtube.svg';
import spotifyIcon from '../images/social-icons/spotify.svg';
import laperronaIcon from '../images/social-icons/laperrona.png';



import Box from '@material-ui/core/Box';
import '../App.css';


const useStyles = makeStyles((theme) => ({

  root: {
    flexGrow: 1
  },

  menuButton: {
    marginRight: theme.spacing(2),
    marginTop: "35px",
    
  },

  mobileMenuButton: {
    backgroundColor: "transparent",
  },

  logo: {
    flexGrow: 1,
    height: "144px",
    width: "154px"
  },

  appBar: {
    boxShadow: "none",
    backgroundColor: "transparent",
    height: "144px",
    backgroundImage: `url(${menuBack})`
  },

  toolBar: {
    justifyContent: "center",
  },

  centerBox: {
    width: "1200px",
    height: "144px"
  },

  centerMobileBox: {
    width: "100%",
    height: "144px"
  },

  menuBar: {
    height: "44px",
    paddingLeft: "20px"
  },

  socialIcons: {
    height: "70px",
    paddingTop: "10px"
  },

  webNav: {
    position: "flex",
    justifyContent: "space-around",
    width: "100%",
    backgroundColor: "#ccc",
    color: "#fff",
    listStyle: "none",
    margin: "auto"
  },
  
  webNavUL: {
    backgroundColor: "transparent"
  },
  
  webNavLI: {
    float: "left",
    display: "block",
    padding: "11px 12px 10px 12px",
    backgroundColor: "#000",
    color: "#fff",
    '&:hover': {
      backgroundColor: "#484848",
      color: "#fff"
    }
  },

    webNavLION: {
      float: "left",
      display: "block",
      padding: "11px 12px 10px 12px",
      backgroundColor: "#fff",
      color: "#000",
      borderBottom: "3px solid #f48120"
    }

}));


const Nav = props => {
  const { history } = props;
  //console.log(history.location.pathname);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isWeb = useMediaQuery(theme.breakpoints.up('md'));
  // console.log(isWeb);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClick = (pageURL) => {
    history.push(pageURL);
    setAnchorEl(null);
  };

  let pathName = history.location.pathname.split("/");
  pathName = pathName[1];
  //console.log(pathName);
  
  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>

          { 

            isWeb ? (

              <>
                {/* Este Box es el contenedor central de tamaño 1200px */}
                <Box display="flex" flexWrap="wrap" flexDirection="row" className={classes.centerBox}>

                  {/* Este Box contiene el logo */}
                  <Box display="flex">
                    <img src={LPMLogo} alt="Latin Power Music" title="Latin Power Music" width="154" height="144" />
                  </Box>

                  {/* Este Box está a la par del Box del logo, es para crear dos componentes que floten a la izquierda,
                  pero el contenido de este componente especificamente es tipo column */}
                  <Box display="flex" flexDirection="column" flexGrow={1}>

                    {/* Este Box contiene todos los iconos de redes sociales */}
                    <Box display="flex" justifyContent="flex-end" className={classes.socialIcons}>
                      <a href="http://www.facebook.com/latinpowermusic" target="_blank" rel="noreferrer"><img src={facebookIcon} alt="Facebook" title="Facebook" height="50" /></a>
                      <a href="https://instagram.com/latinpowermusic" target="_blank" rel="noreferrer"><img src={instagramIcon} alt="Instagram" title="Instagram" height="50" /></a>
                      <a href="http://twitter.com/latinpowermusic" target="_blank" rel="noreferrer"><img src={twitterIcon} alt="Twitter" title="Twitter" height="50" /></a>
                      <a href="http://www.youtube.com/user/LATINPOWERMUSIC" target="_blank" rel="noreferrer"><img src={youtubeIcon} alt="YouTube" title="YouTube" height="50" /></a>
                      <a href="https://goo.gl/RZWREh" target="_blank" rel="noreferrer"><img src={spotifyIcon} alt="Spotify" title="Spotify" height="50" /></a>
                      <a href="http://laperrona.mx" target="_blank" rel="noreferrer"><img src={laperronaIcon} alt="La Perrona Mx" title="La Perrona Mx" height="50" /></a>
                    </Box>

                    {/* Este Box contiene el menu de navegacion */}
                    <Box display="flex" justifyContent="flex-start" className={ classes.menuBar }>
                      <ul className={classes.webNavUL}>
                        <Link to="/"><li className={ history.location.pathname === "/" ? classes.webNavLION : classes.webNavLI }>Inicio</li></Link>
                        {/* <Link to="/noticias"><li className={ pathName === "noticias" ? classes.webNavLION : classes.webNavLI }>Noticias</li></Link> */}
                        {/* <Link to="/agenda"><li className={ pathName === "agenda" ? classes.webNavLION : classes.webNavLI }>Agenda</li></Link> */}
                        <Link to="/artistas"><li className={ pathName === "artistas" ? classes.webNavLION : classes.webNavLI }>Artistas</li></Link>
                        {/* <Link to="/musica"><li className={ pathName === "musica" ? classes.webNavLION : classes.webNavLI }>Música</li></Link> */}
                        {/* <Link to="/videos"><li className={ pathName === "videos" ? classes.webNavLION : classes.webNavLI }>Videos</li></Link> */}
                        {/* <Link to="/store"><li className={ pathName === "store" ? classes.webNavLION : classes.webNavLI }>Store</li></Link> */}
                        <Link to="/corporativo"><li className={ pathName === "corporativo" ? classes.webNavLION : classes.webNavLI }>Corporativo</li></Link>
                        <Link to="/informes"><li className={ pathName === "informes" ? classes.webNavLION : classes.webNavLI }>Informes</li></Link>
                      </ul>
                    </Box>

                    {/* Este Box contiene el telefono de contacto */}
                    <Box display="flex" justifyContent="flex-end">
                      <Typography variant="caption" color="textPrimary">
                        Informes y Contrataciones: +52 (81)1605 2537, +52 (81)1234 3789
                      </Typography>
                    </Box>

                  </Box>

                </Box>
              </>               
            ) : (
              <>

                {/* Este Box es el contenedor central de tamaño 1200px */}
                <Box display="flex" flexWrap="wrap" flexDirection="row" className={classes.centerMobileBox}>

                  <Box display="flex" flexGrow={1}>
                    <img src={LPMLogo} alt="Latin Power Music" title="Latin Power Music" width="154" height="144" />
                  </Box>                  

                  <Box display="flex" className={classes.mobileMenuButton}>

                      <IconButton 
                        edge="start" 
                        className={classes.menuButton} 
                        color="inherit" 
                        onClick={handleMenu}
                        aria-label="menu"
                      >
                        <MenuIcon />
                      </IconButton>              

                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={open}
                        onClose={() => setAnchorEl(null)}
                      >
                        <MenuItem onClick={() => handleMenuClick('/')}>Inicio</MenuItem>
                        <MenuItem onClick={() => handleMenuClick('/noticias')}>Noticias</MenuItem>
                        <MenuItem onClick={() => handleMenuClick('/agenda')}>Agenda</MenuItem>
                        <MenuItem onClick={() => handleMenuClick('/artistas')}>Artistas</MenuItem>
                        <MenuItem onClick={() => handleMenuClick('/musica')}>Música</MenuItem>
                        <MenuItem onClick={() => handleMenuClick('/videos')}>Videos</MenuItem>
                        <MenuItem onClick={() => handleMenuClick('/store')}>Store</MenuItem>
                        <MenuItem onClick={() => handleMenuClick('/corporativo')}>Corporativo</MenuItem>
                        <MenuItem onClick={() => handleMenuClick('/informes')}>Informes</MenuItem>
                      </Menu>

                  </Box>

                </Box>

              </>

            ) 
              
          }

        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withRouter(Nav);

