import React from 'react';
import LanzamientosList from '../components/Lanzamientos';

function Musica() {
  return (
    <div>
        <h1>Musica</h1>
        <LanzamientosList />
    </div>
  );
}

export default Musica;
