import React from 'react';
import '../App.css';

function Videos() {
  return (
    <div>
        <h1>Videos</h1>
    </div>
  );
}

export default Videos;