import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { Grid } from '@material-ui/core'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import datapath from '../datapath';



const useStyles = makeStyles((theme) => ({

  root: {
    minWidth: 275,
    //borderTopRightRadius: 10,
    //borderRadius: 0,
    border: 0,
    overfow: 'hidden',
    '& > svg': {
        margin: theme.spacing(2),
      },
  },

  cardMedia: {
    height: 350,
    width: '100%',
    objectFill: 'cover',
    objectPosition: 'center 20%',
  },


  cardContent: {
    height: 48,
    paddingTop: 5,
    backgroundColor: '#f8f8f8',
  },

  cardAction: {
    height: 48,
    display: 'flex', 
    justifyContent: 'center',
  },

  iconColors: {
    color: "#888",
    minWidth: '36px',
    '&:hover': {
      color: "#000",
    }
  },  

  linkHover: {
    '&:hover': {
      color: "#ff8204",
    }
  },  

}));

function SpotifyIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="m11.999937,1.568495c-5.761188,0 -10.431696,4.670168 -10.431696,10.431357s4.670507,10.431653 10.431696,10.431653c5.761358,0 10.431823,-4.670465 10.431823,-10.431653s-4.670507,-10.431357 -10.431823,-10.431357zm4.232057,15.353873c-0.168641,0 -0.283923,-0.058488 -0.441545,-0.154528c-1.512009,-0.914622 -3.396987,-1.395286 -5.397713,-1.395286c-1.116195,0 -2.239596,0.143 -3.290352,0.361653c-0.170803,0.037127 -0.385811,0.103245 -0.514189,0.103245c-0.396153,0 -0.660325,-0.314947 -0.660325,-0.655578c0,-0.438578 0.252856,-0.656511 0.567464,-0.716694c1.288439,-0.293586 2.571623,-0.461253 3.904437,-0.461253c2.282614,0 4.317077,0.523725 6.067744,1.572871c0.260528,0.152197 0.413233,0.307403 0.413233,0.693341c0.000042,0.376063 -0.305665,0.65223 -0.648755,0.65223zm1.136709,-2.761032c-0.226367,0 -0.3689,-0.091377 -0.522581,-0.180805c-1.696925,-1.007017 -4.048793,-1.676073 -6.624231,-1.676073c-1.320989,0 -2.461004,0.184789 -3.404743,0.436289c-0.203353,0.055861 -0.317448,0.116044 -0.50762,0.116044c-0.448623,0 -0.814853,-0.365595 -0.814853,-0.81782c0,-0.443537 0.215008,-0.748948 0.648839,-0.871265c1.172734,-0.322025 2.370092,-0.570431 4.113046,-0.570431c2.730601,0 5.372071,0.680076 7.448747,1.922826c0.348175,0.199878 0.476383,0.45303 0.476383,0.824516c0.000085,0.451801 -0.359619,0.816718 -0.812988,0.816718zm1.294203,-3.211053c-0.212381,0 -0.33847,-0.051622 -0.537542,-0.160631c-1.888114,-1.132089 -4.818254,-1.755626 -7.6521,-1.755626c-1.414528,0 -2.851265,0.143847 -4.167296,0.500881c-0.151561,0.038145 -0.343047,0.114095 -0.534914,0.114095c-0.556445,0 -0.983325,-0.440188 -0.983325,-0.996633c0,-0.566998 0.351312,-0.885209 0.730342,-0.997184c1.487003,-0.438069 3.146801,-0.642228 4.951294,-0.642228c3.063137,0 6.285888,0.630064 8.640045,2.01272c0.317236,0.179703 0.537542,0.450742 0.537542,0.947342c-0.000042,0.568609 -0.457184,0.977264 -0.984045,0.977264z" />
    </SvgIcon>
  );
}


const SocialIcons = ({artist, classes}) => {

  const fComponent = 
    artist.facebook !== "" ?
      <Button key={"facebook-icon"} target="_blank" href={artist.facebook} size="small" className={classes.iconColors}>
        <FacebookIcon />
      </Button>     
    :
      ""
  const IComponent = 
    artist.instagram !== "" ?
      <Button key={"instagram-icon"} target="_blank" href={artist.instagram} size="small" className={classes.iconColors}>
          <InstagramIcon />
      </Button>
    :
      ""  
  const TComponent = 
        artist.twitter !== "" ?
            <Button key={"twitter-icon"} target="_blank" href={artist.twitter} size="small" className={classes.iconColors}>
                <TwitterIcon />
            </Button>
        :
            ""
  const YComponent = 
        artist.youtube !== "" ?
            <Button key={"youtube-icon"} target="_blank" href={artist.youtube} size="small" className={classes.iconColors}>
                <YouTubeIcon />
            </Button>
        :
            ""
  const SComponent = 
        artist.spotify !== "" ?
            <Button key={"spotify-icon"} target="_blank" href={"https://open.spotify.com/artist/" + artist.spotify} size="small" className={classes.iconColors}>
                <SpotifyIcon />
            </Button>
        :
            ""
  return [fComponent, IComponent, TComponent, YComponent, SComponent]
}

const openClick = (url) => {
  window.open(url, "_blank");
}

const RenderArtistCard = ({artist, classes}) => {

  const placeholder = datapath + "empty_artist.png";
  const image = artist.spotify_image !== "" ? artist.spotify_image : placeholder;

  return (
    <Card className={classes.root} variant="outlined">
        <CardActionArea>
            <Link rel="noopener" to={'/artistas/' + artist.modo}>
                <CardMedia
                component="img"
                alt={artist.nombre}
                src={image}
                title={artist.nombre}
                className={classes.cardMedia}
                />
            </Link>                
            <CardContent className={classes.cardContent} >
                <Typography variant="subtitle2" component="h3" noWrap>
                    {artist.nombre}
                </Typography>
                <Typography variant="caption" noWrap onClick={() => openClick(artist.web !== "" ? artist.web : "#")} className={classes.linkHover}>
                    { artist.web !== "" ? artist.web : "" }
                </Typography>
            </CardContent>
        </CardActionArea>
        <CardActions className={classes.cardAction}>
          <SocialIcons artist={artist} classes={classes} />
        </CardActions>
    </Card>
  );
}




const RenderBrochure = ({artistDB, classes}) => {

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12}>
        <Typography align="left" variant="h4" component="h2">
          Artistas                
        </Typography>
      </Grid> 

        {
          artistDB.map(item => {
            return item.status === "1" ?
              <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                <RenderArtistCard artist={item} classes={classes} />
              </Grid>
            :
              ""
          })
        }

    </Grid>
  )
}



const displayInfo = (artistDB, classes) =>{

  const didMount = typeof artistDB !== 'undefined' ? true : false;

  //console.log(didMount);
  let skeletonWidth = 300;
  let skeletonHeight = 350;

  return (
          didMount ? 
            <RenderBrochure artistDB={artistDB} classes={classes} />
          : 
            <>
              <Grid container direction="row" spacing={1} style={{marginTop: "0px"}}>
                <Grid item xs={12}>
                  <Typography align="left" variant="h4" component="h2">
                    Artistas                
                  </Typography>
                </Grid>          
                <Grid item>
                  <Skeleton variant="rect" width={skeletonWidth} height={skeletonHeight} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Grid>
                <Grid item>
                  <Skeleton variant="rect" width={skeletonWidth} height={skeletonHeight} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Grid>
                <Grid item>
                  <Skeleton variant="rect" width={skeletonWidth} height={skeletonHeight} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Grid>
                <Grid item>
                  <Skeleton variant="rect" width={skeletonWidth} height={skeletonHeight} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Grid>
                <Grid item>
                  <Skeleton variant="rect" width={skeletonWidth} height={skeletonHeight} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Grid>
                <Grid item>
                  <Skeleton variant="rect" width={skeletonWidth} height={skeletonHeight} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Grid>
              </Grid>
            </>
    );

}


const ArtistasBrochure = ({artistDB}) => {

  const classes = useStyles();
  // console.log(artistDB);

  return (
    <>
      {displayInfo(artistDB, classes)}
    </>
  );
}

export default ArtistasBrochure
