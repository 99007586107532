import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { Grid } from '@material-ui/core'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import datapath from '../datapath';

const useStyles = makeStyles((theme) => ({

  root: {
    Width: 'auto',
    //borderTopRightRadius: 10,
    //borderRadius: 0,
    //border: 0,
    overfow: 'hidden',
    '& > svg': {
        margin: theme.spacing(2),
      },
  },

  album: {
    [theme.breakpoints.down('md')]: {
      width: 175,
      minHeight: 250,
    },      

    [theme.breakpoints.up('md')]: {
      width: 250,
      minHeight: 300,
    },      
  },

}));

var decodeHtmlEntity = function(x) {
  return x.replace(/&#(\d+);/g, function(match, dec) {
    return String.fromCharCode(dec);
  });
};

const openClick = (url) => {
  window.open(url, "_blank");
}

const RenderSingles = ({singleDB, classes}) => {

  const placeholder = datapath + "empty_artist.png"

  if(!singleDB.length) return false;
 
  return (
    <>
      <Grid container direction="row" spacing={3} style={{marginTop: "0px"}}>
        <Grid item xs={12}>
          <Typography align="left" variant="h4" component="h2">
            Sencillos                
          </Typography>
        </Grid>    
          {
            singleDB.map(album => {

              album.titulo = decodeHtmlEntity(album.titulo);
              
              return (
                <Grid key={album.id} item className={classes.album}>
                  <Card className={classes.root} variant="outlined">
                    <CardActionArea onClick={() => openClick(album.url)}>
                      <CardMedia
                        component="img"
                        alt={album.titulo}
                        src={album.portada !== "" ? album.portada : placeholder}
                        title={album.titulo}
                        className={classes.cardMedia}
                      />
                    </CardActionArea>
                  </Card>
                  <Typography gutterBottom align="left" variant="subtitle2" component="h3">
                    {album.titulo}
                  </Typography>
                  <Typography align="left" variant="caption" color="textSecondary" component="p">
                    {album.release_date.substring(0,4)}
                  </Typography>
                </Grid>
              )
            })
          }
        </Grid>
    </>
  )
}


const displayInfo = (singleDB, classes) =>{

  const placeholder = datapath + "empty_artist.png";
  const didMount = typeof singleDB !== 'undefined' ? true : false;

  //if(didMount) console.log(singleDB);

  return (
          didMount ? 
            <RenderSingles singleDB={singleDB} classes={classes} />
          : 
            <>
              <Grid container direction="row" spacing={3} style={{marginTop: "0px"}}>
                <Grid item xs={12}>
                  <Typography align="left" variant="h4" component="h2">
                    Sencillos                
                  </Typography>
                </Grid>          
                <Grid item className={classes.album}>
                  <Skeleton variant="rect" width={155} height={155} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Grid>
                <Grid item className={classes.album}>
                  <Skeleton variant="rect" width={155} height={155} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Grid>
                <Grid item className={classes.album}>
                  <Skeleton variant="rect" width={155} height={155} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Grid>
                <Grid item className={classes.album}>
                  <Skeleton variant="rect" width={155} height={155} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Grid>
                <Grid item className={classes.album}>
                  <Skeleton variant="rect" width={155} height={155} />
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Grid>
              </Grid>
            </>
    );

}



export default function ArtistaSingles(props) {
  const classes = useStyles();
  //console.log(props.data);

  return (
    <>
      {displayInfo(props.data, classes)}
    </>
  );
}

