import React from 'react';
import EventosList from '../components/Eventos';

function Agenda() {
  return (
    <div>
        <h1>Agenda</h1>
        <EventosList />
    </div>
  );
}

export default Agenda;
