import { Grid } from '@material-ui/core'
import YouTube from 'react-youtube'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
//import { ThemeProvider, useTheme, createMuiTheme } from '@material-ui/core/styles';

/**
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

function videoWidthBP(size){
  switch(size) {
    case 'xs':
      return 342;
    case 'sm':
      return 554;
    case 'md':
      return 800;
    default:
      return 960;
  }
}

function videoHeightBP(size){
  switch(size) {
    case 'xs':
      return 192;
    case 'sm':
      return 312;
    case 'md':
      return 450;
    default:
      return 540;
  }
}

const DisplayInfo = ({artistDB}) =>{

  //console.log(artistDB);

  return (
    <Grid container direction="column" wrap="nowrap" spacing={3} style={{marginTop: '20px'}}>
      <Grid item xs={12}>
        <Typography align="left" variant="h4" component="h2">
          Videos Musicales                
        </Typography>
      </Grid>        
      <Grid item style={{alignContent: 'center', width: '100%', backgroundColor: '#111'}}>
        <YouTube
          opts={{
            width: videoWidthBP(useWidth()),
            height: videoHeightBP(useWidth()),
            playerVars: {
              autoplay: 1,
                listType:'playlist',
                list: artistDB.youtube_playlist  
            }         
          }}
        />
      </Grid>
    </Grid>
  )
}


export default function YTChannelVideos(props) {

  const didMount = typeof props.data !== 'undefined' ? true : false;
  if(!didMount) return <></>

  const noPlaylist = typeof props.data.youtube_playlist !== 'undefined' & props.data.youtube_playlist !== "" ? true : false;
  if(!noPlaylist) return <></>


  return (
    <>
      <DisplayInfo artistDB={props.data} />
    </>      
  )
}
