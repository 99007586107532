import React, { Component } from 'react';
import axios from 'axios';
import he from 'he';

export default class NoticiasList extends Component {
  
  state = {
    items: []
  }

  componentDidMount() {

    const params = new URLSearchParams();
    params.append('limite', 5);
    params.append('pg', 1);
    params.append('filtro', 'all');

    axios.post(`http://lpm.mx/api/publico-noticias/list`, params, {use: 'no-cors'})
      .then(res => {
        const items = res.data.result;
        this.setState({ items });
        console.log(this.state.items);
      })
  }

  render() {
    return (
      <div>
        <ul>
            { this.state.items.map(item => <li>{he.decode(item.titulo)}</li>)}
        </ul>
      </div>
    )
  }
}