import React from 'react';
import '../App.css';

function Informes() {
  return (
    <div>
        <h1>Informes</h1>
    </div>
  );
}

export default Informes;