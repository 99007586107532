import React from 'react';
import NoticiasList from '../components/Noticias';

function Noticias() {
  return (
    <div>
        <h1>Noticias</h1>
        <NoticiasList />
    </div>
  );
}

export default Noticias;
