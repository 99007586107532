import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { Grid } from '@material-ui/core'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import GetAppIcon from '@material-ui/icons/GetApp';
import LaunchIcon from '@material-ui/icons/Launch';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import datapath from '../datapath';

const useStyles = makeStyles((theme) => ({

  root: {
    Width: 'auto',
    //borderTopRightRadius: 10,
    //borderRadius: 0,
    //border: 0,
    overfow: 'hidden',
    '& > svg': {
        margin: theme.spacing(2),
      },
  },

  artistImage: {
    margin: theme.spacing(2),
  },

  cardMedia: {
    height: 430,
    
    objectFill: 'cover',
    borderRadius: 10,
    objectPosition: 'center 20%',
    padding: theme.spacing(2),
  },

  fIconColors: {
    color: "#888",
    [theme.breakpoints.only('xs')]: {
      minWidth: '36px',
    },    
    '&:hover': {
      backgroundColor: "#ffffff",
      color: "#3b5999",
    }
  },

  IIconColors: {
    color: "#888",
    [theme.breakpoints.only('xs')]: {
      minWidth: '36px',
    },  
    '&:hover': {
      backgroundColor: "#ffffff",
      color: "#bc2a8d",
    }
  },

  TIconColors: {
    color: "#888",
    [theme.breakpoints.only('xs')]: {
      minWidth: '36px',
    },  
    '&:hover': {
      backgroundColor: "#ffffff",
      color: "#55acee",
    }
  },

  YIconColors: {
    color: "#888",
    [theme.breakpoints.only('xs')]: {
      minWidth: '36px',
    },  
    '&:hover': {
      backgroundColor: "#ffffff",
      color: "#bb0000",
    }
  },

  SIconColors: {
    color: "#888",
    [theme.breakpoints.only('xs')]: {
      minWidth: '36px',
    },  
    '&:hover': {
      backgroundColor: "#ffffff",
      color: "#1ed760",
    }
  },

}));


function SpotifyIcon(props) {
  return (
    <SvgIcon {...props}>
      <path d="m11.999937,1.568495c-5.761188,0 -10.431696,4.670168 -10.431696,10.431357s4.670507,10.431653 10.431696,10.431653c5.761358,0 10.431823,-4.670465 10.431823,-10.431653s-4.670507,-10.431357 -10.431823,-10.431357zm4.232057,15.353873c-0.168641,0 -0.283923,-0.058488 -0.441545,-0.154528c-1.512009,-0.914622 -3.396987,-1.395286 -5.397713,-1.395286c-1.116195,0 -2.239596,0.143 -3.290352,0.361653c-0.170803,0.037127 -0.385811,0.103245 -0.514189,0.103245c-0.396153,0 -0.660325,-0.314947 -0.660325,-0.655578c0,-0.438578 0.252856,-0.656511 0.567464,-0.716694c1.288439,-0.293586 2.571623,-0.461253 3.904437,-0.461253c2.282614,0 4.317077,0.523725 6.067744,1.572871c0.260528,0.152197 0.413233,0.307403 0.413233,0.693341c0.000042,0.376063 -0.305665,0.65223 -0.648755,0.65223zm1.136709,-2.761032c-0.226367,0 -0.3689,-0.091377 -0.522581,-0.180805c-1.696925,-1.007017 -4.048793,-1.676073 -6.624231,-1.676073c-1.320989,0 -2.461004,0.184789 -3.404743,0.436289c-0.203353,0.055861 -0.317448,0.116044 -0.50762,0.116044c-0.448623,0 -0.814853,-0.365595 -0.814853,-0.81782c0,-0.443537 0.215008,-0.748948 0.648839,-0.871265c1.172734,-0.322025 2.370092,-0.570431 4.113046,-0.570431c2.730601,0 5.372071,0.680076 7.448747,1.922826c0.348175,0.199878 0.476383,0.45303 0.476383,0.824516c0.000085,0.451801 -0.359619,0.816718 -0.812988,0.816718zm1.294203,-3.211053c-0.212381,0 -0.33847,-0.051622 -0.537542,-0.160631c-1.888114,-1.132089 -4.818254,-1.755626 -7.6521,-1.755626c-1.414528,0 -2.851265,0.143847 -4.167296,0.500881c-0.151561,0.038145 -0.343047,0.114095 -0.534914,0.114095c-0.556445,0 -0.983325,-0.440188 -0.983325,-0.996633c0,-0.566998 0.351312,-0.885209 0.730342,-0.997184c1.487003,-0.438069 3.146801,-0.642228 4.951294,-0.642228c3.063137,0 6.285888,0.630064 8.640045,2.01272c0.317236,0.179703 0.537542,0.450742 0.537542,0.947342c-0.000042,0.568609 -0.457184,0.977264 -0.984045,0.977264z" />
    </SvgIcon>
  );
}

const SocialIcons = ({artista, classes}) => {

  const fComponent = 
    artista.facebook !== "" ?
      <Button key={"facebook-icon"} target="_blank" href={artista.facebook} size="large" className={classes.fIconColors}>
        <FacebookIcon />
      </Button>
    :
      ""
  const IComponent = 
    artista.instagram !== "" ?
      <Button key={"instagram-icon"} target="_blank" href={artista.instagram} size="large" className={classes.IIconColors}>
          <InstagramIcon />
      </Button>
    :
      ""  
  const TComponent = 
        artista.twitter !== "" ?
            <Button key={"twitter-icon"} target="_blank" href={artista.twitter} size="large" className={classes.TIconColors}>
                <TwitterIcon />
            </Button>
        :
            ""
  const YComponent = 
        artista.youtube !== "" ?
            <Button key={"youtube-icon"} target="_blank" href={artista.youtube} size="large" className={classes.TIconColors}>
                <YouTubeIcon />
            </Button>
        :
            ""
  const SComponent = 
        artista.spotify !== "" ?
            <Button key={"spotify-icon"} target="_blank" href={"https://open.spotify.com/artist/" + artista.spotify} size="large" className={classes.SIconColors}>
                <SpotifyIcon />
            </Button>
        :
            ""
  return [fComponent, IComponent, TComponent, YComponent, SComponent]
}

const openClick = (url) => {
  window.open(url, "_blank");
}


const ArtistEPK = ({artista, classes}) => {

  const web = 
    artista.web !== "" ?
      <ListItem key={"web-link"} button onClick={() => openClick(artista.web)}>
        <ListItemIcon>
          <LaunchIcon />
        </ListItemIcon>
        <ListItemText primary={artista.web} secondary="El sitio web se abrirá en una ventana nueva" />
      </ListItem>
    :
      ""
  const bio = 
    artista.biografia !== "" ?
      <ListItem key={"biografia-link"} button onClick={() => openClick(datapath + "epk/d.php?f=" + artista.biografia)}>
        <ListItemIcon>
          <GetAppIcon />
        </ListItemIcon>
        <ListItemText primary="Biografía" secondary="Contiene biografía e imagen oficial" />
      </ListItem>
    :
      ""  
  const brochure = 
        artista.brochure !== "" ?
          <ListItem key={"brochure-link"} button button onClick={() => openClick(datapath + "epk/d.php?f=" + artista.brochure)}>
            <ListItemIcon>
              <GetAppIcon />
            </ListItemIcon>
            <ListItemText primary="Brochure" secondary="Brochure informativo para prensa" />
          </ListItem>
        :
            ""
  const rider = 
        artista.rider !== "" ?
          <ListItem key={"rider-link"} button button onClick={() => openClick(datapath + "epk/d.php?f=" + artista.rider)}>
            <ListItemIcon>
              <GetAppIcon />
            </ListItemIcon>
            <ListItemText primary="Rider Técnico" secondary="Contiene Rider, Input List y Stage Plot" />
          </ListItem>
        :
            ""

  return [web, bio, brochure, rider]
}


const displayInfo = (artista, classes) =>{

  const placeholder = datapath + "empty_artist.png";
  const didMount = typeof artista !== 'undefined' ? true : false;

  return (
    <Card className={classes.root} variant="outlined">
      <Grid container direction="row" spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          {
            didMount ? 
              <CardMedia
                component="img"
                alt={artista.nombre}
                src={artista.spotify_image !== "" ? artista.spotify_image : placeholder}
                title={artista.nombre}
                className={classes.cardMedia}
              />
            :
              <Skeleton className={classes.artistImage} variant="rect" width="auto" height={430} />
          }
        </Grid>
        <Grid container item xs={12} sm={12} md={6} lg={7} spacing={0} direction="column" wrap="nowrap">
          <CardContent>
            <Grid item>
              <Typography gutterBottom variant="h4" component="h1">
                {
                  didMount ? 
                    artista.nombre
                  :
                    <Skeleton variant="text" />
                }                  
              </Typography>
            </Grid>
            <Grid container item style={{justifyContent: 'center', backgroundColor: '#f5f5f5'}}>
              <CardActions>
                {
                  didMount ? 
                    <SocialIcons artista={artista} classes={classes} />
                  :
                    <Skeleton variant="text" />
                } 
              </CardActions>
            </Grid>
            <Divider />
            <Grid item>
              {
                didMount ? 
                  <List>
                    {
                      didMount ? 
                        <ArtistEPK artista={artista} classes={classes} />
                      :
                        <Skeleton variant="text" />
                    } 
                  </List>                
                : 
                <>
                  <Skeleton variant="text" style={{marginTop: '20px'}} />
                  <Skeleton variant="text" width="70%" />
                </>
              }               
            </Grid>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );

}





export default function ArtistaInfo(props) {
  const classes = useStyles();

  return (
    <>
      {displayInfo(props.data, classes)}
    </>
  );
}

