import React from 'react';
import '../App.css';

function Corporativo() {
  return (
    <div>
        <h1>Corporativo</h1>
    </div>
  );
}

export default Corporativo;