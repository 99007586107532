import React from 'react';
import '../App.css';

function Store() {
  return (
    <div>
        <h1>Store</h1>
    </div>
  );
}

export default Store;